import React from 'react';
import { useState, useEffect } from 'react';
import './Dashboard.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from '../components/layout';
import axios from 'axios';
// import ShopName from './token/ShopName';
import { Link } from 'react-router-dom';
import { ShopName } from './token/ShopName';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';


function Dashboard() {
    var storedValue = sessionStorage.getItem('code');



    const shopname = ShopName()

    const [newDate, setNewDate] = useState(new Date());
    const [apiData, setApiData] = useState([]);
    const [plan, setPlan] = useState([])
    const [siteReview, setSiteReview] = useState()



    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());



    // const handleTotalCustomer = async () => {
    //     try {
    //         const config = await axios({
    //             url: "https://productreview.ens.enterprises/ratingContoller/totalCustomer",
    //             method: "POST",
    //             headers: {
    //                 'Authorization': storedValue,
    //                 'Content-Type': 'application/json'
    //             },
    //             data: {
    //                 shopName: shopname
    //             }
    //         })
    //         // console.log("review filter data", config.data)
    //         setApiData(config.data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const handleDPlanDetail = async () => {
        try {
            const config = await axios.post("https://productreview.ens.enterprises/planContoller/getPlanDetail", {
                headers: {
                    'Authorization': storedValue,
                    'Content-Type': 'application/json'
                },
                shop: shopname,
            })
            // console.log("==============", config.data.planName)
            setPlan(config.data)
        } catch (error) {
            console.log(error)
        }
    }

    // analytics product review api
    const handleAllSiteReview = async () => {
        try {
            const config = await axios({
                url: "https://productreview.ens.enterprises/ratingSiteContoller/totalCustomerSite",
                method: "POST",
                headers: {
                    'Authorization': storedValue,
                    'Content-Type': 'application/json'
                },
                data: {
                    shopName: shopname
                }
            })
            // console.log("review filter data", config.data)
            setSiteReview(config.data)
        } catch (error) {
            console.log(error)
        }
    }
    // console.log(plan)
    useEffect(() => {
        // handleTotalCustomer()
        handleDPlanDetail()
        // handleAllSiteReview()
        handleAPICall()
    }, [])

    const handleAPICall = async () => {
        console.log("startDate",startDate)
        // console.log("endDate",endDate)

        const originalDate = new Date(startDate);
        // console.log("originalDate",originalDate)
        originalDate.setDate(originalDate.getDate() + 1);
        const formattedDate = originalDate.toISOString().split('T')[0];

        const originalDate1 = new Date(endDate);
        originalDate1.setDate(originalDate1.getDate() + 1);
        const formattedDate1 = originalDate1.toISOString().split('T')[0];
        // console.log("formattedDate", formattedDate)
        // console.log("formattedDate1",formattedDate1)

        try {
            const config = await axios({
                url: "https://productreview.ens.enterprises/ratingContoller/totalCustomer",
                method: "POST",
                headers: {
                    'Authorization': storedValue,
                    'Content-Type': 'application/json'
                },
                data: {
                    shopName: shopname,
                    fromDate: formattedDate,
                    toDate: formattedDate1
                }
            })
            console.log(config.data, "----------------------->>>>")
            setApiData(config.data)
        } catch (error) {
            console.log(error)
        }
        try {
            const config = await axios({
                url: "https://productreview.ens.enterprises/ratingSiteContoller/totalCustomerSite",
                method: "POST",
                headers: {
                    'Authorization': storedValue,
                    'Content-Type': 'application/json'
                },
                data: {
                    shopName: shopname,
                    fromDate: formattedDate,
                    toDate: formattedDate1
                }
            })
            setSiteReview(config.data)
        } catch (error) {
            console.log(error)
        }
    }
    return (

        <div style={{ height: "600px" }}>
            {
                plan.status === 200 ?

                    <>
                        {plan.planName === "free" ?
                            
                                <Link to='/plan'><p className='fs-3 text-center text-primary ' style={{display: 'ruby-text'}}>
                                To unlock this feature, please upgrade your plan. </p></Link>
                            :
                            <>
                                <div className='wrap'>
                                    <div className="d-flex justify-content-between" >
                                        <p className='fw-semibold fs-5 ms-3'> Product Review Analytics</p>
                                        <div className='d-flex'>
                                            <div>
                                                <label><b>To</b></label>&nbsp;&nbsp;
                                                <DatePicker
                                                className='p-2'
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    dateFormat="yyyy,MMMM d"
                                                />

                                             </div>&nbsp;&nbsp;
                                            <div>
                                                <label><b>From</b></label>&nbsp;&nbsp;
                                                <DatePicker
                                                className='p-2'
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    dateFormat="yyyy,MMMM d"
                                                />

                                            </div>&nbsp;&nbsp;
                                            <button className='btn btn button_bg_css' onClick={handleAPICall}>Filter</button>
                                        </div>
                                    </div>
                                    <div className='wrap2 p-3'>
                                        <p style={{ fontSize: '20px' }}></p>

                                        <div className='wrap3'>

                                            <div className='div1'>
                                                <h6>Total Customer</h6>
                                                <p className='card_content' >{apiData?.totalUser}</p>
                                            </div>
                                            <div className='div1'>
                                                <h6>Total Reviews</h6>
                                                <p className='card_content' >{apiData?.totalUser}</p>
                                            </div>
                                            <div className='div2'>
                                                <h6>Verified</h6>
                                                <p className='card_content'>{apiData?.verfiedUser}</p>
                                            </div>
                                            <div className='div3'>
                                                <h6>Unverified(Guest)</h6>
                                                <p className='card_content'>{apiData?.unVerifiedUser}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='wrap'>
                                    <div className="d-flex justify-content-between" >
                                        <p className='fw-semibold fs-5 ms-3'>Site Review Analytics</p>
                                        <div className='d-none'>
                                            <DatePicker
                                                showIcon
                                                selected={newDate}
                                            />
                                        </div>
                                    </div>

                                    <div className='wrap2 p-3'>
                                        <p style={{ fontSize: '20px' }}></p>

                                        <div className='wrap3'>

                                            <div className='div1'>
                                                <h6>Total Customer</h6>
                                                <p className='card_content' >{siteReview?.totalUser}</p>
                                            </div>
                                            <div className='div1'>
                                                <h6>Total Reviews</h6>
                                                <p className='card_content' >{siteReview?.totalUser}</p>
                                            </div>
                                            <div className='div2'>
                                                <h6>Verified</h6>
                                                <p className='card_content'>{siteReview?.verfiedUser}</p>
                                            </div>
                                            <div className='div3'>
                                                <h6>Unverified(Guest)</h6>
                                                <p className='card_content'>{siteReview?.unVerifiedUser}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                        }
                    </> :
                    <>
                        <div className='d-flex justify-content-center'>
                            <div className="spinner-border text-primary" role="status" style={{marginTop:"200px"}} >
                                <span className="visually-hidden ">Loading...</span>
                            </div>
                        </div>
                    </>
            }


        </div>

    );
}


export default Dashboard;