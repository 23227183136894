import React from 'react'
import Sidebar from './sidebar';
import Header from './header';
import { Outlet } from 'react-router-dom';
import Settings from '../pages/settings';
import { Route, Routes } from 'react-router-dom';

function Layout({ children }) {
    return (
        <div>
            <Header />
            <div className='d-flex'>
                <Sidebar />
                <div className='w-100' style={{ background: "#f6f6f7" }}>
                    <Outlet />
                </div>
            </div>


        </div>
    )
}

export default Layout;