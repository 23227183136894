import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Rating } from 'react-simple-star-rating'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


function AddReview() {
    var storedValue = sessionStorage.getItem('code');
    const nevigate = useNavigate()

    const [product, setProduct] = useState([])
    const [addreview, setAddReview] = useState({
        productname: "",
        productreviewtittle: "",
        productreviewtext: "",
        reviewDate: "",
        autherName: "",
        email: "",
        rating: "",

    })


    const code = sessionStorage.getItem("code")
    const decode = jwtDecode(code)
    const shopname = decode.jwttoken


    const handleAddReview = (e) => {
        setAddReview({
            ...addreview,
            [e.target.name]: e.target.value
        })
    }

    const handleRating = (rate) => {
        setAddReview({
            ...addreview,
            rating: rate
        })
    }

    const canclefrom = () => {
        nevigate("/ProductReviews")

    }
    // api for listing all product

    const allProductListing = async () => {
        try {
            const config = await axios({
                url: "https://productreview.ens.enterprises/ratingContoller/allProductListing",
                method: "POST",
                headers: {
                    'Authorization': storedValue,
                    'Content-Type': 'application/json'
                },
                data: {
                    shopName: shopname
                }
            })
            console.log(config.data.data)
            setProduct(config.data.data)
        } catch (error) {
            console.log(error)
        }


    }

    const handlePostReview = async () => {
        if (!addreview?.rating) {
          
            toast.warning("Please Choose Rating", {
                autoClose: 1500
            })
        } else if (!addreview?.productreviewtext) {
            toast.warning("Please Write Review", {
                autoClose: 1500
            })

        } else if (!addreview?.productname) {
            toast.warning("Please Select Product", {
                autoClose: 1500
            })
        } else {
            try {
                const config = await axios.post("https://productreview.ens.enterprises/ratingContoller/addDummyData", {
                    shopName: shopname,
                    rating: addreview.rating,
                    product_id: product[0].id,
                    sku: product[0].sku,
                    product_handle: addreview.productname,
                    reviewDate: addreview.reviewDate,
                    body: addreview.productreviewtext,
                    reply: "",
                    title: addreview.productreviewtittle,
                    customer_email: addreview.email,
                    customer_name: addreview.autherName
                })

                setProduct(config.data.filteredSnowboards)

                if (config.status === 201) {
                    toast.success("Review Added successfully", {
                        autoClose: 1500
                    })
                    nevigate("/ProductReviews")

                }

            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        allProductListing()

    }, [])
  
    return (

        <div style={{ height: "600px" }}>

            <Link to={'/ProductReviews'} className='text-dark text-decoration-none ms-4'>Back</Link>

            <div className='w-75 p-4 container shadow' style={{ background: "white" }}>
                <div className='container'>
                    <p>Add Review</p>

                    <hr />

                    <div className='d-flex justify-content-between'>
                        <div className="mb-3 col-5">
                            <label className="form-label">Product Name</label>
                            <select name='productname' value={addreview.productname}
                                className="form-select drop_select clear"
                                onChange={handleAddReview}
                            >
                                {/* <option selected >Product</option> */}
                                {
                                    product?.map((value, index) => {

                                        return (
                                            <option key={index}>{value.handle}</option>
                                        )
                                    })
                                }
                            </select>

                        </div>
                        <div className="mb-3 col-5">
                            <label className="form-label">Review date</label>
                            <input type="date" name='reviewDate' className="form-control" onChange={handleAddReview} />
                        </div>
                    </div>

                    <div className='d-flex justify-content-between mb-2'>
                        <div className="mb-3 col-5">
                            <label className="form-label">Auther Name</label>
                            <input type="text" name='autherName' value={addreview.autherName} className="form-control" onChange={handleAddReview} />

                        </div>
                        <div className="mb-3 col-5">
                            <label className="form-label">Email</label>
                            <input type="email" name='email' value={addreview.email} className="form-control" onChange={handleAddReview} />
                        </div>
                    </div>


                    <label className='form-label'>Rating </label>
                    <div className='mb-2'>
                        <Rating onClick={handleRating} value={addreview.rating} name="rating" />

                    </div>



                    <div class="mb-3">
                        <label class="form-label">Review Tttle</label>
                        <input type="text" class="form-control" name='productreviewtittle' onChange={handleAddReview} />

                    </div>
                    <div class="mb-3">
                        <label class="form-label">Review body</label>
                        <input type="text" class="form-control" name='productreviewtext' onChange={handleAddReview} />
                    </div>

                    <div className='d-flex justify-content-end'>

                        <button type="submit" className="btn btn-secondary"
                            onClick={canclefrom}
                        >Cancel
                        </button>

                        <button type="submit" className="btn btn-primary ms-3"
                            onClick={handlePostReview}
                        >Add review
                            <ToastContainer />
                        </button>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default AddReview;