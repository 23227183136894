import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

function AddQuestion() {
    const code = sessionStorage.getItem("code");
    const decode = jwtDecode(code);
    const shopname = decode.jwttoken;

    const [QuestionValue, setQuestionValue] = useState('');
    const [allQuestionValue, setAllQuestionValue] = useState([]);
    const [settingsdata, setSettingsdata] = useState({
        Question: "1",
    });
    const [questionID, setQuestionID] = useState();
    const [QuestionValueUpdate, setQuestionValueUpdate] = useState('');

    const handlePermessions = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setSettingsdata({
                ...settingsdata,
                [name]: checked ? "1" : "0"
            });
        } else {
            setSettingsdata({
                ...settingsdata,
                [name]: value
            });
        }
    };

    const AddQuestion = () => {
        let newQuestion={
            question:QuestionValue,
            // questionID: questionID.toString()
        }
        setAllQuestionValue([...allQuestionValue, newQuestion]);
        setQuestionValue('');

        // if (QuestionValue.trim() !== '') {
        //     const newQuestion = {
        //         question: QuestionValue,
        //         questionID: questionID.toString()
        //     };
        //     setAllQuestionValue([...allQuestionValue, newQuestion]);
        //     setQuestionValue('');
        // }
        // setQuestionID(questionID + 1);

    };

    

    const handleChange = (e) => {
        setQuestionValue(e);
    };

    const createQuestion = async () => {
        console.log("allQuestionValue", allQuestionValue)
        try {
            const response = await axios.post('https://productreview.ens.enterprises/ratingContoller/createQuestion', {
                Questions: allQuestionValue,
                shopName: shopname
            });
            // if(response.status ==200){
            toast.success("question Added successfully", {
                autoClose: 1500
            })
            // }

        } catch (error) {
            console.error("Error submitting questions:", error);
        }
    };
    const deletefun = (id) => {
        console.log(id)
        let data = [...allQuestionValue]
        data.splice(id, 1)
        setAllQuestionValue(data)
    }
    const editfun = (question) => {
        setQuestionValue(question)
        setQuestionValueUpdate(question)
    }
    return (
        <div style={{ height: "600px", marginTop: "8px" }} className="d-flex">
            <div
                className="p-2 m-2 rounded shadow w-50"
                style={{ background: "white", height: "fit-content" }}
            >
                <div>
                    <div className="m-2 mt-3 d-flex">
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={settingsdata?.Question === "1"}
                                name="Question"
                                onChange={handlePermessions}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <h3>Add Question</h3>
                <div className='d-flex'>
                    <textarea
                        className="form-control"
                        id="storyTextarea2"
                        onChange={(e) => handleChange(e.target.value)}
                        rows="1"
                        placeholder="Write a Question for user..."
                        value={QuestionValue}
                    />
                    &nbsp;&nbsp;
                    <div>
                        {QuestionValueUpdate ? <><button className='btn btn-primary' onClick={AddQuestion}>update</button></> :
                            <><button className='btn btn-primary' onClick={AddQuestion}>Add</button></>}
                    </div>
                </div>
                {allQuestionValue?.map((item) => (
                    <ul key={item.questionID}>
                        <li className='d-flex p-2 bd-highlight'>
                            <span onClick={() => editfun(item.question)}>{item.question}</span>
                            {/* <span onClick={() => deletefun(item.questionID)}></span> */}
                        </li>
                    </ul>
                ))}
                {allQuestionValue.length > 0 && (
                    <button className='btn btn-primary' onClick={createQuestion}>Submit</button>
                )}
                <ToastContainer />
            </div>
        </div>
    );
}

export default AddQuestion;
