import React from 'react';

function CustomCss() {
  return (
 
      <div className='container' style={{ height: "600px" }}>
        <div className='mt-3' >
          <p className='fw-semibold fs-5'>Please Write your custom CSS here</p>
          <textarea className='w-50 mb-3' rows="10"></textarea>
        </div>
        <button className='btn btn-primary'> Save</button>
        <p className='text-danger mt-4 fw-semibold'> * Note : Your Changes Will Appear On Your Website</p>
      </div>
  )
}

export default CustomCss;