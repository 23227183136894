import React from "react";
import Layout from "../../components/layout";
import './SiteReview.css';
import { HiOutlineStar } from "react-icons/hi2";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { useState, useEffect } from "react";
import image from '../../icons/facewash.webp';
import { MdPersonOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { jwtDecode } from 'jwt-decode';
import axios from "axios";
import { ShopName } from "../token/ShopName";
import { ToastContainer, toast } from 'react-toastify';


// const code = sessionStorage.getItem("code")
// const decoded = jwtDecode(code)
// const shopname = decoded.jwttoken
// console.log(shopname)
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
};

function SiteReview({ getSiteReviewUpdateData }) {

    const shopname = ShopName();

    const [currentPage, setCurrentPage] = useState(1);
    const [siteReview, setSiteReview] = useState([])
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [sitemodal, setSitemodal] = useState("")
    const [plan, setPlan] = useState([])

    const [star, setStar] = useState()
    const [status, setStatus] = useState()
    const [search, setsSearch] = useState()
    const [update_data, setUpdate_data] = useState()
    const [importopen, setImportOpen] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [importLoader, setImportLoader] = useState(false)

    const handleOpen = (content) => {
        setOpen(true)
        setSitemodal(content)
    }
    const handleClose = () => setOpen(false);

    const editReview = () => { };

    console.log(siteReview)
    const itemPerPage = 5; // Number of items per page

    const startIndex = (currentPage - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    const paginatesiteReview = siteReview.slice(startIndex, endIndex);

    const totalPages = Math.ceil(siteReview.length / itemPerPage);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    // finction for site review listing
    const GetAllReviews = async () => {
        try {

            const config = await axios.post("https://productreview.ens.enterprises/ratingSiteContoller/getAllReviewSite", {
                shopName: shopname
            })
            // console.log(config.data.reviews)
            setSiteReview(config.data.reviews)

        } catch (error) {
            console.log(error)
        }
    }

    // confirmation for delete the review
    function confirmDeleteSiteReview(id) {
        var confirmation = window.confirm("Are you sure you want to delete this site Review?");
        if (confirmation) {
            deletesiteReview(id)
        } else {
            toast.error("Site Review Delete process failed", {
                autoClose: 1500
            });
        }
    }
    // function for delete the sitereview
    const deletesiteReview = async (product_id) => {
        // console.log(product_id)
        try {
            const config = await axios.post("https://productreview.ens.enterprises/ratingSiteContoller/deleteReviewSite", {
                id: product_id
            })

            console.log(config)
            if (config.status === 200) {
                toast.success("Site Review Delete successfully", {
                    autoClose: 1500
                })
            }
            GetAllReviews()
        } catch (error) {
            console.log(error)
        }
    }

    // confirmation for delete the review

    function confirmDeleteSiteReview(id) {
        var confirmation = window.confirm("Are you sure you want to delete this site Review?");
        if (confirmation) {
            deletesiteReview(id)
        } else {
            toast.error("Site Review Delete process failed", {
                autoClose: 1500
            });
        }
    }
    // function for delete the sitereview
    const siteReviewStatusApprove = async (e, product_id) => {
        console.log("============", e.target.value, product_id)
        try {
            const config = await axios.post("https://productreview.ens.enterprises/ratingSiteContoller/approvedCommentSite", {
                id: product_id,
                published: e.target.value
            })
            setUpdate_data(config.data)

            // console.log(config)
        } catch (error) {
            console.log(error)
        }
    }

    // function for filter review by there status  sitereview
    const filtersiteReviewByStatus = async (e) => {
        console.log('first')
        try {
            const config = await axios.post("https://productreview.ens.enterprises/ratingSiteContoller/findDataRatingStatusSite", {
                shopName: shopname,
                rating: star,
                published: status,
                searchTerm: search
            })

            // console.log(config.data.data)
            setSiteReview(config.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        filtersiteReviewByStatus()
    }, [star, status, search])

    const handleDPlanDetail = async () => {
        try {
            const config = await axios.post("https://productreview.ens.enterprises/planContoller/getPlanDetail", {
                shop: shopname,
            })
            // console.log("==============", config.data.planName)
            setPlan(config.data)
        } catch (error) {
            console.log(error)
        }
    }

    const filtersearch = (e) => {
        const inputvalue = e.target.value
        if (inputvalue.length >= 3) {
            setsSearch(e.target.value)
        } else {
            GetAllReviews()
        }
    }
    console.log(search)

    useEffect(() => {
        GetAllReviews()
        handleDPlanDetail()
    }, [])

    useEffect(() => {
        GetAllReviews()
    }, [update_data])

    // -------------------------twak-----support--
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/60b07e5a6699c7280da95c20/1f6omr56c';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
    })();
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleCloseImportFileModal = () => setImportOpen(false)

    const handleDownload = async () => {
        try {
            // Fetch CSV data from the API
            const response = await fetch(`https://productreview.ens.enterprises/exportSiteCSV?shopName=${shopname}`);
            const csvData = await response.text();

            const blob = new Blob([csvData], { type: 'text/csv' });

            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'data.csv';
            a.style.display = 'none';
            document.body.appendChild(a);

            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };
    const handleOpenImportFileModal = () => {
        setImportOpen(true)
    };
    const handleUpload = async () => {
        setImportLoader(true)
        const formData = new FormData();
        formData.append('csvSite', selectedFile);

        try {
            const response = await axios.post(`https://productreview.ens.enterprises/importSiteCSV?shopName=${shopname}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setImportLoader(false)
            setImportOpen(false)
            GetAllReviews()
            // console.log('File uploaded successfully:', response);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const DownloadCSV = () => {
        const headers = [
            'shopName',
            'rating',
            'customer_id',
            'title',
            'body',
            'published',
            'reviewDate',
            'customer_email',
            'customer_name',
            'reply',
        ]; 
        const dummyData = [
          "DummyShop",
          "4",
          "123", 
          "Dummy Product Title",
          "Dummy Review Body",
          "true",
          "2024-04-04",
          "dummy@example.com",
          "Dummy Customer",
          "Dummy Reply", 
        ];
     
        const orderList = Array.from({ length: 3 }, () => dummyData);
    
        const data = orderList.map((item) => {
          return item.join(',');
        });
    
        const csvContent = [headers.join(','), ...data].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `orders_${new Date().getTime()}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    return (
        <div style={{ height: "600px" }}>
            {plan.status === 200 ? <>

                {plan.planName === "free" ? <>
                    <p className='fs-3 text-center text-primary'>
                        To unlock this feature, please upgrade your plan. </p>
                </> : <>
                    <>

                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    Content
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    {sitemodal}
                                </Typography>
                            </Box>
                        </Modal>

                        <Modal
                            open={importopen}
                            onClose={handleCloseImportFileModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    Select Your File
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <input type="file" onChange={handleFileChange} />
                                    <br></br>
                                    <button className="btn mt-3 button_bg_css" onClick={handleUpload} disabled={!selectedFile}>
                                        {importLoader == true ? <>
                                            Loading...

                                        </> : <>Uploads</>}
                                    </button>

                                </Typography>
                            </Box>
                        </Modal>
                        <div className="container mt-2">
                            <div className="row mb-2 review_nav">
                                <div className="col-lg-3 d-flex gap-4">
                                    <div>
                                        <select name="Rate" className="form-select drop_select"
                                            onChange={(e) => { setStar(e.target.value) }}
                                        >
                                            <option selected >Rating</option>
                                            <option value="">All</option>
                                            <option value="1">1 star</option>
                                            <option value="2">2 star</option>
                                            <option value="3">3 star</option>
                                            <option value="4">4 star</option>
                                            <option value="5">5 star</option>
                                        </select>
                                    </div>
                                    <div>
                                        <select name="Status" className="form-select drop_select"
                                            onChange={(e) => { setStatus(e) }}
                                        >
                                            <option selected >Status</option>
                                            <option value="">All</option>
                                            <option value="true">Approved</option>
                                            <option value="false">Disapproved</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-9 d-flex justify-content-between gap-4">
                                    <div className="d-flex w-75">

                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            onChange={filtersearch}
                                        />
                                    </div>
                                    <div className="d-flex">

                                        <button className="form-control drop_select btn btn-primary me-2">
                                            <Link to={"/SiteReview/AddSiteReview"} className="text-light text-decoration-none">Add</Link>
                                        </button>
                                        <button className="btn btn-primary form-control drop_select "
                                            onClick={handleOpenImportFileModal}
                                        >
                                            Import
                                        </button>
                                        <button className="btn btn-primary form-control drop_select  ms-2" onClick={handleDownload}>
                                            Export
                                        </button>

                                        {/* <button className="btn btn-primary form-control drop_select  ms-2" onClick={DownloadCSV}>
                                            Sample
                                        </button> */}
                                    </div>

                                </div>
                            </div>

                            <table className="border review-list">
                                <tr>
                                    <th className="review-id">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" />
                                        </div>
                                    </th>

                                    <th className="rating">
                                        <div >Rating</div>
                                    </th>
                                    <th className="Customer">
                                        <div >Customer</div>
                                    </th>
                                    <th className="siteheading">
                                        <div >Heading</div>
                                    </th>
                                    <th className="content">
                                        <div >Content</div>
                                    </th>
                                    <th className="status">
                                        <div >Status</div>
                                    </th>

                                    <th className="actions">
                                        <div >Action</div>
                                    </th>
                                </tr>

                                {
                                    paginatesiteReview.map((value, index) => {
                                        // console.log(value)
                                        return (
                                            <tr key={index}>
                                                <td className="review-id">
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                        />
                                                    </div>
                                                </td>

                                                <td>

                                                    {value.rating == 5 ? (
                                                        <>
                                                            <span >
                                                                <i class="fa fa-star selected"></i>
                                                                <i class="fa fa-star selected"></i>
                                                                <i class="fa fa-star selected"></i>
                                                                <i class="fa fa-star selected"></i>
                                                                <i class="fa fa-star selected"></i>

                                                            </span>

                                                        </>

                                                    ) : (
                                                        <>
                                                            {value.rating > 0 && (
                                                                <>
                                                                    {[...Array(value.rating)].map((data, index) => (
                                                                        <span key={index}>
                                                                            {data == 4 ? (
                                                                                <>
                                                                                    <i class="fa fa-star selected"></i>
                                                                                    <i class="fa fa-star selected"></i>
                                                                                    <i class="fa fa-star selected"></i>
                                                                                    <i class="fa fa-star selected"></i>
                                                                                    <i class="fa fa-star emptystar"></i>
                                                                                </>
                                                                            ) : (data == 3 ? (
                                                                                <>
                                                                                    <i class="fa fa-star selected"></i>
                                                                                    <i class="fa fa-star selected"></i>
                                                                                    <i class="fa fa-star selected"></i>
                                                                                    <i class="fa fa-star emptystar"></i>
                                                                                    <i class="fa fa-star emptystar"></i>
                                                                                </>
                                                                            ) : (data == 2 ? (
                                                                                <>
                                                                                    <i class="fa fa-star selected"></i>
                                                                                    <i class="fa fa-star selected"></i>
                                                                                    <i class="fa fa-star emptystar"></i>
                                                                                    <i class="fa fa-star emptystar"></i>
                                                                                    <i class="fa fa-star emptystar"></i>
                                                                                </>
                                                                            ) : (data == 1 ? (
                                                                                <>
                                                                                    <i class="fa fa-star selected"></i>
                                                                                    <i class="fa fa-star emptystar"></i>
                                                                                    <i class="fa fa-star emptystar "></i>
                                                                                    <i class="fa fa-star emptystar"></i>
                                                                                    <i class="fa fa-star emptystar"></i>
                                                                                </>
                                                                            ) : (null))))}


                                                                        </span>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                </td>
                                                <td className="Customer">
                                                    <p>{value?.customer_name}</p>
                                                </td>
                                                <td className="siteheading">
                                                    <p>{value?.title}</p>
                                                </td>
                                                <td className="content">
                                                    <p onClick={() => { handleOpen(value?.body) }} className="review-full">
                                                        {value?.body?.length > 25 ? value?.body.substring(0, 25) + "..." : value?.body}
                                                    </p>


                                                </td>
                                                <td className="status">
                                                    <select name="Status" className={`${value.published === "1" ? "text-success drop_select form-select" : "text-danger drop_select form-select"}`}
                                                        onChange={(e) => { siteReviewStatusApprove(e, value._id) }}
                                                    >
                                                        <option value="1" selected={value.published === "1" ? true : false}>Approved</option>
                                                        <option value="0" selected={value.published === "0" ? true : false}>Disapproved</option>
                                                    </select>
                                                </td>
                                                <td className="actions">
                                                    <div className="">
                                                        <Link to={"./EditSiteReview"}>
                                                            <FaRegEdit
                                                                onClick={() => { getSiteReviewUpdateData(value) }}
                                                                style={{ color: "#000000" }}
                                                            />
                                                        </Link>
                                                        <MdDeleteOutline
                                                            onClick={() => { confirmDeleteSiteReview(value._id) }}

                                                        />

                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </table>

                            <div className="pagination justify-content-center mt-5">
                                <button
                                    className="btn btn-primary me-3"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    {/* Previous */}◀️
                                </button>
                                <span>
                                    {" "}
                                    Page {currentPage} of {totalPages}{" "}
                                </span>
                                <button
                                    className="btn btn-primary ms-3"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    {/* Next */} ▶️
                                </button>
                            </div>
                        </div>
                    </>
                </>}
            </> : <>
                <div className='d-flex justify-content-center'>
                    <div className="spinner-border text-primary" role="status" style={{ marginTop: "200px" }} >
                        <span className="visually-hidden ">Loading...</span>
                    </div>
                </div>
            </>
            }

        </div>

    )
}

export default SiteReview;