import React from "react";
import { useNavigate } from 'react-router-dom';
import './home.css';
import axios from "axios";

const Home = ({ input, setInput }) => {
  const navigate = useNavigate();


  const changeValue = (event) => {
    const newValue = event.target.value;
    setInput(newValue.replace('https://', '').replace('/', ''));
    console.log("input", input)
  }

  // const handleInstall = async(e)=>{
  //   // e.preventDefaults();
  //   console.log(input);
  //   try {
  //     const response = await axios.get('https://productreview.ens.enterprises/shopify', {
  //         params: input
  //     });

  //     if (response.status === 200) {
  //         window.location.href = response.data;
  //     } else {
  //         console.error('Unexpected response status:', response.status);
  //     }
  // } catch (error) {
  //     console.error('Error:', error.message);
  // }
  // }

  const handleInstall = async () => {
    // console.log({ input })
    const response = await axios.get('https://productreview.ens.enterprises/shopify', {
      params: {shop:input }
    });
    console.log(response.status)
    if (response.status === 200) {
      window.location.href = response.data;
    }
  }
  // console.log({ input });

  return (
    <form className="needs-validation login" novalidate>
      <div className="containerBox ">
        <div className="form-group mb-4">
          <label forHtml="exampleInputEmail1">Enter your URL Shop</label>&nbsp;
          <input
            type="url"
            name="shop"
            required
            autoComplete="off"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={changeValue}
            placeholder="ShopName.myshopify.com"
          />
        </div>



        <button className="btn btn-large btn-block btnSubmit" type="button" onClick={handleInstall} name="submit" >Submit</button>
      </div>
    </form>
  );
};

export default Home;
