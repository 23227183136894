import React, { useState } from 'react'
import Layout from '../../components/layout';
import '../support/support.css';
import { ShopName } from '../token/ShopName';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


function Support() {

    const shopname = ShopName();
    const [plan, setPlan] = useState([])
    // function for support api

    const handlesupport = async () => {
        try {
            const config = await axios.post("https://productreview.ens.enterprises/support/supportMail", {
                shopName: shopname
            })

            if (config.data.message === 'flag1') {
                toast.success(config.data.data, {
                    autoClose: 1500
                })
            }

        } catch (error) {
            console.log(error)
        }
    }
    // plan detail
    const handleDPlanDetail = async () => {
        try {
            const config = await axios.post("https://productreview.ens.enterprises/planContoller/getPlanDetail", {
                shop: shopname,
            })
            // console.log("==============", config.data.planName)
            setPlan(config.data)
        } catch (error) {
            console.log(error)
        }
    }

    useState(() => {
        handleDPlanDetail()
    }, [])
    const bookMetting=()=>{
        window.location = "https://calendly.com/ens-app-support/15min?back=1&month=2024-03"
    }

    return (
        <>
            <div className="help ">
                <div className="help-box">
                    <div className="p-3 m-3 shadow contact-box">
                        <h2>Contact Us</h2> <br />
                        <p>ENS Enterprises will be able to address any questions or issues you have with Reviews & Ratings app.</p>
                        <p style={{ color: 'red' }}>For Quick help please fix a meeting with us by clicking button below!</p>
                        <button onClick={bookMetting} className={`${plan.planName === 'free' ? "btn btn-primary plan_btn mt-2" : "btn btn-primary mt-2"}`} type='submit'>Book a Meeting</button>
                    </div>

                    <div className="editor-box m-3 shadow" aria-description='Write your query...'>
                        <h3>Write your query here</h3> <br />
                        <textarea name="query" id="editor" placeholder='Raise your query...'></textarea>
                        <button className='btn btn button_bg_css send-btn'
                            onClick={handlesupport}
                        >Send Message</button>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Support;

