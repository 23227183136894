import React, { useEffect } from "react";
import Layout from "../components/layout";
import { useState } from "react";
import "./settings.css";
import { ShopName } from "./token/ShopName";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


function Settings() {

  const shopname = ShopName()

  const [togglehandler, settogglehandler] = useState(false);
  const [settingsdata, setSettingsdata] = useState({
    disable: "",
    allReview: "",
    autoApproved: "",
    displayOnListingpage: "",
    enableDate: "",
    enableTimeStamp: "",
    automaticallyPublishReview: "",
    reviewTitle: "",
    displayOnlyVerifiedUsers: "",
    reviewStarCheker:""
  });

  const [siteSettingsdata, setSiteSettingsdata] = useState({
    automaticallyPublishReview: "",
    allReview: "",
    disable: "",
    autoApproved: "",
    reviewPostion: "",
    enableDate: "",
    enableTimeStamp: "",
    reviewTitle: "",
    displayOnlyVerifiedUsers: ""
  });

  const [isHovered, setIsHovered] = useState();

  const handleMouseEnter = (data) => {
    setIsHovered(data);
    // console.log("isHovered",isHovered)
  };

  const handleMouseLeave = () => {
    setIsHovered('');
  };

  // console.log(siteSettingsdata)

  const handlePermessions = (e) => {
    // console.log(e.target.value)
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setSettingsdata({
        ...settingsdata,
        [name]: checked ? "1" : "0"
      });
    } else {
      setSettingsdata({
        ...settingsdata,
        [name]: value
      });
    }
  };

  const handleSiteReviewPermessions = (e) => {
    // console.log(e.target.value)
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setSiteSettingsdata({
        ...siteSettingsdata,
        [name]: checked ? "1" : "0"
      });
    } else {
      setSiteSettingsdata({
        ...siteSettingsdata,
        [name]: value
      });
    }
  };

  // data for update the setting
  const data = settingsdata.shopName = shopname
  // console.log(data)
  //get review settings function
  const handleSettings = async () => {
    try {
      const config = await axios({

        url: "https://productreview.ens.enterprises/ratingContoller/getSetting",

        method: "POST",
        data: {
          shopName: shopname
        }
      })
      // console.log("review filter data", config.data.reviewSiteSetting[0])
      setSettingsdata(config.data.reviewSiteSetting[0])
    } catch (error) {
      console.log(error)
    }
  }

  // Update settings function
  const handleUpdateSetting = async () => {
    try {
      const config = await axios({
        url: "https://productreview.ens.enterprises/ratingContoller/setting",
        method: "POST",
        data: settingsdata
      })
      // console.log(config.status)
      if (config.status === 200) {
        toast.success("Settings Updated successfully", {
          autoClose: 1500
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  // ============ site review==================
  //get site review settings function
  const GetSiteSettings = async () => {
    try {
      const config = await axios({

        url: "https://productreview.ens.enterprises/ratingSiteContoller/getSiteReviewSetting",

        method: "POST",
        data: {
          shopName: shopname
        }
      })
      // console.log("site review data", config.data.reviewSiteSetting[0])
      setSiteSettingsdata(config.data.reviewSiteSetting[0])
    } catch (error) {
      console.log(error)
    }
  }

  const sitereviewdata = siteSettingsdata.shopName = shopname
  // console.log(siteSettingsdata)
  // =====update site review
  const UpdateSiteReviewSetting = async () => {
    try {
      const config = await axios({
        url: "https://productreview.ens.enterprises/ratingSiteContoller/siteReviewsetting",
        method: "POST",
        data: siteSettingsdata
      })
      // console.log(config.status)
      if (config.status === 200) {
        toast.success("Site Review Settings Updated successfully", {
          autoClose: 1500
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleSettings()
    GetSiteSettings()
  }, [])
  // -------------------------twak-----support--
  var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/60b07e5a6699c7280da95c20/1f6omr56c';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
  return (
    <>
      <div style={{ height: "600px",marginTop:"8px" }} className="d-flex">
        <div
          className="p-2 m-2 rounded shadow w-50"
          style={{ background: "white", height: "fit-content" }}
        >
          <div className="d-flex justify-content-between">
            <p className="fw-semibold mb-2 font">Product Review Setting</p>
            <div className="m-2 d-flex">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={settingsdata?.disable == "1" ? true : false}
                  name="disable"
                  onChange={handlePermessions}
                />
                <span className="slider round"></span>
              </label>

              <div>Disable / Enable</div>
            </div>
          </div>
          <div className="w-100 p-2 d-flex rounded border-bottom publish align-items-center justify-content-between">
            <div className="d-flex">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={settingsdata?.automaticallyPublishReview == "1" ? true : false}
                  name="automaticallyPublishReview"
                  onChange={handlePermessions}
                />
                <span className="slider round"></span>
              </label>
              <div> Reviews Preview</div>
            </div>
            <div className="ms-5 mb-2 mt-1">
              <select name="allReview" className="form-select drop_select" value={settingsdata?.allReview}
                onChange={(e) => { handlePermessions(e) }}

              >
                <option value="all" selected>All Reviews</option>
                {/* <option value="1">1+ star reviews</option>
                <option value="2">2+ star reviews</option> */}
                <option value="3">3 and Above</option>
                <option value="4">4 and Above</option>
                <option value="5">5 </option>
              </select>
            </div>
          </div>

          <div className="mt-2 mb-2 justify-content-between for_icone">
            <div >

              {/* <div className=" m-2 mt-3 d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settingsdata?.disable == "1" ? true : false}
                    name="disable"
                    onChange={handlePermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div>Disable / Enable</div>
              </div> */}

              <div className=" m-2 mt-3 d-flex ">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settingsdata?.autoApproved == "1" ? true : false}
                    name="autoApproved"
                    onChange={handlePermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div onMouseEnter={() => handleMouseEnter('Approve')} onMouseLeave={handleMouseLeave}>Auto Approve</div>
                <p className="text_manage_css">{isHovered == 'Approve' ? <>Publish Review without approval</> : null}</p>


              </div>

              {/* <div className="m-2 mt-3 d-flex ">
                <label className="switch">
                  <input 
                    type="checkbox"
                    checked={settingsdata?.displayOnListingpage == "1" ? true : false}
                    name="displayOnListingpage"
                    onChange={handlePermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div onMouseEnter={() => handleMouseEnter('Listing')} onMouseLeave={handleMouseLeave} >Product Listing </div>
                <p className="text_manage_css">{isHovered == 'Listing' ? <>List your Product</> : null}</p>

              </div> */}
              <div className="m-2 mt-3 d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settingsdata?.displayOnlyVerifiedUsers == "1" ? true : false}
                    name="displayOnlyVerifiedUsers"
                    onChange={handlePermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div onMouseEnter={() => handleMouseEnter('Verified_user')} onMouseLeave={handleMouseLeave}>Verified Users</div>
                <p className="text_manage_css">{isHovered == 'Verified_user' ? <>To see only Login user</> : null}</p>
              </div>
            </div>

            <div >
              <div className=" m-2 mt-3 d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settingsdata?.enableDate == "1" ? true : false}
                    name="enableDate"
                    onChange={handlePermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div onMouseEnter={() => handleMouseEnter('show_date')} onMouseLeave={handleMouseLeave}>Show Date</div>
                <p className="text_manage_css">{isHovered == 'show_date' ? <>To show Review date</> : null}</p>
              </div>

              <div className=" m-2 mt-3 d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settingsdata?.enableTimeStamp == "1" ? true : false}
                    name="enableTimeStamp"
                    onChange={handlePermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div onMouseEnter={() => handleMouseEnter('show_time')} onMouseLeave={handleMouseLeave}>Show Time Stap</div>
                <p className="text_manage_css">{isHovered == 'show_time' ? <>To show Review time</> : null}</p>
              </div>

              <div className="mt-3 d-flex m-2 review_title">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settingsdata?.reviewTitle == "1" ? true : false}
                    name="reviewTitle"
                    onChange={handlePermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div onMouseEnter={() => handleMouseEnter('title')} onMouseLeave={handleMouseLeave}>Require Review Tittle</div>
                <p className="text_manage_css">{isHovered == 'title' ? <>Enable Review title</> : null}</p>
              </div>


              <div className="mt-3 d-flex m-2 review_title">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settingsdata?.reviewStarCheker == "1" ? true : false}
                    name="reviewStarCheker"
                    onChange={handlePermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div onMouseEnter={() => handleMouseEnter('reviewStarCheker')} onMouseLeave={handleMouseLeave}>Review Star Cheker </div>
                <p className="text_manage_css">{isHovered == 'reviewStarCheker' ? <>Enable Review Star Cheker</> : null}</p>
              </div>

            </div>
            <br></br>
          </div>
          <button className="btn btn button_bg_css mb-2 ms-2" onClick={() => { handleUpdateSetting() }}>Update</button>
          <ToastContainer />
          {/* <br/><br/>
          <br/><br/> */}
         
        </div>



        <div
          className="p-2 m-2 rounded shadow w-50"
          style={{ background: "white", height: "fit-content" }}
        >
          <div className="d-flex justify-content-between">
            <p className="fw-semibold mb-2 font">Site Review Setting</p>
            <div className="m-2  d-flex">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={siteSettingsdata?.disable == "1" ? true : false}
                  name="disable"
                  onChange={handleSiteReviewPermessions}
                />
                <span className="slider round"></span>
              </label>

              <div>Disable / Enable</div>
            </div>
          </div>
          <div className="w-100 p-2 d-flex rounded border-bottom publish align-items-center justify-content-between">
            <div className="d-flex">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={siteSettingsdata?.automaticallyPublishReview == "1" ? true : false}
                  name="automaticallyPublishReview"
                  onChange={handleSiteReviewPermessions}
                />
                <span className="slider round"></span>
              </label>
              <div>Auto Publish Reviews</div>
            </div>

            <div className="ms-5 mb-2 mt-1">
              <select name="allReview" className="form-select drop_select" value={siteSettingsdata?.allReview}
                onChange={(e) => { handleSiteReviewPermessions(e) }}

              >
                <option value="all" selected>All Reviews</option>
                <option value="3">3 and Above</option>
                <option value="4">4 and Above</option>
                <option value="5">5 </option>
              </select>
            </div>
          </div>

          <div className=" mt-2 mb-2 justify-content-between">
            <div >

              {/* <div className=" m-2 mt-3 d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={siteSettingsdata?.disable == "1" ? true : false}
                    name="disable"
                    onChange={handleSiteReviewPermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div>Disable / Enable</div>
              </div> */}

              <div className=" m-2 mt-3 d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={siteSettingsdata?.autoApproved == "1" ? true : false}
                    name="autoApproved"
                    onChange={handleSiteReviewPermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div>Auto Approve</div>
              </div>

              <div className="m-2 mt-3 d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={siteSettingsdata?.displayOnlyVerifiedUsers == "1" ? true : false}
                    name="displayOnlyVerifiedUsers"
                    onChange={handleSiteReviewPermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div>Only Verified Users</div>
              </div>


            </div>

            <div >
              <div className=" m-2 mt-3 d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={siteSettingsdata?.enableDate == "1" ? true : false}
                    name="enableDate"
                    onChange={handleSiteReviewPermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div>Enable Date</div>
              </div>

              <div className=" m-2 mt-3 d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={siteSettingsdata?.enableTimeStamp == "1" ? true : false}
                    name="enableTimeStamp"
                    onChange={handleSiteReviewPermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div>Time Stap</div>
              </div>

              <div className="mt-3 d-flex m-2 review_title">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={siteSettingsdata?.reviewTitle == "1" ? true : false}
                    name="reviewTitle"
                    onChange={handleSiteReviewPermessions}
                  />
                  <span className="slider round"></span>
                </label>

                <div>Require Review Tittle</div>
              </div>

             

              <div className=" ms-2 mb-2 mt-1">
                <label className="mb-2" >Review Position</label>
                <select name="reviewPostion" className="form-select drop_select" value={siteSettingsdata?.reviewPostion}
                  onChange={(e) => { handleSiteReviewPermessions(e) }}
                >
                  <option value="left" selected>Left</option>
                  <option value="right">Right</option>
                </select>

              </div>
            </div>


          </div>
          <button className="btn btn button_bg_css mb-2 ms-2" onClick={UpdateSiteReviewSetting}>Update</button>
          <ToastContainer />

        </div>



      </div>

    </ >
  );
}

export default Settings;
