import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import "./Plan.css";
import { ShopName } from './token/ShopName';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa6";
import { IoMdCheckmark } from "react-icons/io";
function Plan() {
  const shopname = ShopName()
  const nevigate = useNavigate()
  const [plan, setPlan] = useState([])

  // function for paid plan
  const handlePaidPlan = async () => {
    try {

      const config = await axios.post("https://productreview.ens.enterprises/planContoller/planCreate", {

        shopName: shopname,
        planValue: 2
      })
      // console.log(config.data)
      if (config.status === 200) {
        window.location.href = config.data
      }
      // setProduct(config.data.data)

    } catch (error) {
      console.log(error)
    }
  }

  // function for free plan
  const handleFreePlan = async () => {
    try {
      const config = await axios.post("https://productreview.ens.enterprises/planContoller/freePlan", {
        shopName: shopname,
      })
      // console.log("==============", config)

      if (config.status === 200) {
        nevigate("/Appereance")
        window.location.reload()
      }

    } catch (error) {
      console.log(error)
    }
  }

  // function for free plan
  const EnterprisessPlan = async () => {
    try {
      const config = await axios.post("https://productreview.ens.enterprises/planContoller/planCreateSecond", {
        shopName: shopname,
        planValue: 3
      })
      console.log("==============", config)
      if (config.status === 200) {
        window.location.href = config.data
      }

    } catch (error) {
      console.log(error)
    }
  }

  const handleDPlanDetail = async () => {
    try {
      const config = await axios.post("https://productreview.ens.enterprises/planContoller/getPlanDetail", {
        shop: shopname,
      })
      console.log("==============", config.data.planName)
      setPlan(config.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    handleDPlanDetail()
  }, [])

  return (
    <div className='container mt-2' style={{ height: "600px" }}>
      <div className="d-flex justify-content-evenly mt-5">
        <div className="box1">
          <h5 className="heading5" style={{ margin: "1.5rem 1rem" }}>
            Free Plan
          </h5>
          <div>

            <p className="para">Free</p>
           

            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                {/* <img src="./asset/tick1.png" alt="tick1" /> */}
                <IoMdCheckmark />
              </span>
              Appereance
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                {/* <img src="./asset/tick1.png" alt="tick1" /> */}
                <IoMdCheckmark />
              </span>
              Product Review 25 per Product
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
              <RxCross2 />
                {/* <img src="./asset/cross.png" alt="cross" /> */}
              </span>
              Analytics Data
            </p>
            
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                {/* <img src="./asset/cross.png" alt="cross" /> */}
                <RxCross2 />
              </span>
              Site Review
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                {/* <img src="./asset/cross.png" alt="cross" /> */}
                <RxCross2 />
              </span>
              24x7 Support
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                {/* <img src="./asset/cross.png" alt="cross" /> */}
                <RxCross2 />
              </span>
              NPS Review
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                {/* <img src="./asset/cross.png" alt="cross" /> */}
                <RxCross2 />
              </span>
              Review with Images
            </p>
          </div>
          {plan.planName == 'free' ? <>
            <button className="btn22_actived"

            >Activated</button>
          </> : <>
            <button className="btn22"
              onClick={handleFreePlan}
            >Activate</button>
          </>}

        </div>


        <div className="box1">
          <h5 className="heading5" style={{ margin: "1.5rem 1rem" }}>
            Basic Plan
          </h5>
          <p className="para">$4.99 / Month</p>
          <div> 
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <IoMdCheckmark />
              </span>
              Analytics Data
            </p>

            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <IoMdCheckmark />
              </span>
              Appereance
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <IoMdCheckmark />
              </span>
              Product Review 100 per Product
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <IoMdCheckmark />
              </span>
              Site Review 50 Review
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <IoMdCheckmark />
              </span>
              24x7 Support
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <IoMdCheckmark />
              </span>
              NPS Review
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <IoMdCheckmark />
              </span>
              Review with Images
            </p>
          </div>
          {plan.planName == 'Basic' ? <>
            <button className="btn22_actived"

            >Activated</button>
          </> : <>
            <button className="btn22"
              onClick={handlePaidPlan}
            >Activate</button>
          </>}

        </div>

        {/* <div className="box1">
          <h5 className="heading5" style={{ margin: "1.5rem 1rem" }}>
            Enterprisess Plan
          </h5>
          <p className="para">$49.99 / Month</p>
          <div> 
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <img src="./asset/tick1.png" alt="tick1" />
              </span>
              Analytics Data
            </p>

            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <img src="./asset/tick1.png" alt="tick1" />
              </span>
              Appereance
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <img src="./asset/tick1.png" alt="tick1" />
              </span>
              product Review Unlimited
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <img src="./asset/tick1.png" alt="tick1" />
              </span>
              Site Review Unlimited
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <img src="./asset/tick1.png" alt="tick1" />
              </span>
              No Meeting schedule
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <img src="./asset/tick1.png" alt="tick1" />
              </span>
              N P S Review
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <img src="./asset/tick1.png" alt="tick1" />
              </span>
              Review Images
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <img src="./asset/tick1.png" alt="tick1" />
              </span>
              Gigya Integration
            </p>
            <p style={{ padding: "4px 2px 1px 18px", fontWeight: "500" }}>
              <span style={{ marginRight: "3%" }}>
                <img src="./asset/tick1.png" alt="tick1" />
              </span>
              Email integration for Product review
            </p>
          </div>
          {plan.planName == 'Enterprisess' ? <>
            <button className="btn22_actived"

            >Activated</button>
          </> : <>
            <button className="btn22"
              onClick={EnterprisessPlan}
            >Activate</button>
          </>}

        </div> */}
      </div>

    </div>

  )
}

export default Plan;

