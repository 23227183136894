import React from 'react';
import { useState } from "react";
import Layout from '../../../components/layout'
import { HiOutlineStar } from "react-icons/hi2";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Rating } from 'react-simple-star-rating'
import config from '../../../utils/config';
import { ShopName } from '../../token/ShopName';
import { useNavigate } from 'react-router-dom';


function EditSiteReview({ siteReviewUpdate, setSiteReviewUpdate }) {

    const shopname = ShopName();
    const nevigate = useNavigate()

    // console.log(siteReviewUpdate._id)

    const handlechangeEditSiteReview = (e) => {
        setSiteReviewUpdate({
            ...siteReviewUpdate,
            [e.target.name]: e.target.value
        })
    }

    const starRating = (rate) => {
        setSiteReviewUpdate({
            ...siteReviewUpdate,
            rating: rate
        })
    }

    // approve or dissaprove comment
    const filtersiteReviewByStatus = async (e) => {
        try {
            const config = await axios.post("https://productreview.ens.enterprises/ratingSiteContoller/findDataRatingStatusSite", {
                shopName: shopname,
                published: e.target.value,
            })
            console.log(config.status)
            if (config.status === 200) {
                toast(" status Update succesfully..!")

            }
        } catch (error) {
            console.log(error)
        }
    }

    // update site review data
    const handleUpdatedata = async (e) => {
        try {
            const config = await axios.post("https://productreview.ens.enterprises/ratingSiteContoller/approvedCommentSite", {
                id: siteReviewUpdate._id,
                //  published:"", 
                title: siteReviewUpdate.title,
                body: siteReviewUpdate.body,
                rating: siteReviewUpdate.rating,
            })
            // console.log(config.status)
            if (config.status === 200) {
                toast("Site Review Update succesfully..!")
            }
            nevigate("/SiteReview")

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>


            <div className='d-flex flex-column align-items-center  mt-3' style={{ height: "600px" }}>
                <div className=' p-3 m-2' style={{ background: "#FFFFFF", boxShadow: "0px 0px 5px gray", width: "45%" }}>
                    <div className='d-flex'>
                        <p>Rating </p>
                        <div className='ms-5'>
                            <Rating onClick={(e) => { starRating(e) }}
                                initialValue={siteReviewUpdate?.rating}
                                name="rating"
                            />
                        </div>
                    </div>


                    <div className='d-flex'>
                        <p className='mt-2'>Customer </p>
                        <input type='text' className='form-control m-3'
                            value={siteReviewUpdate?.customer_name}
                            name='customer_name'
                            onChange={(e) => { handlechangeEditSiteReview(e) }}
                        />
                    </div>

                    <div className='d-flex'>
                        <p className='mt-2'>Title </p>
                        <input type='text' className='form-control m-3 ms-5'
                            value={siteReviewUpdate?.title}
                            name='title'
                            onChange={(e) => { handlechangeEditSiteReview(e) }}
                        />
                    </div>
                    <div className='d-flex'>
                        <p className='mt-2'>Body </p>
                        <textarea className='form-control m-3 ms-5' style={{ height: "150px" }}
                            value={siteReviewUpdate?.body}
                            name='body'
                            onChange={(e) => { handlechangeEditSiteReview(e) }}
                        ></textarea>
                    </div>

                    <div className='d-flex'>
                        <label>status </label>
                        <select name="published" style={{ width: "30%" }}
                            className={`${siteReviewUpdate?.published === "true" ? "text-success drop_select form-select ms-5" : "text-danger drop_select form-select ms-5"}`}
                            onChange={(e) => { filtersiteReviewByStatus(e) }}
                        >
                            <option value="true" selected={siteReviewUpdate?.published === "true" ? true : false}>Approved</option>
                            <option value="false" selected={siteReviewUpdate?.published === "false" ? true : false}>Disapproved</option>
                        </select>
                    </div>
                </div>
                <button className='btn btn-primary mt-4' onClick={handleUpdatedata}>Update</button>
                <ToastContainer />
            </div>
        </>
    )
}

export default EditSiteReview;