import React from 'react'
import '../Dashboard.css';
function index() {
    return (
        <div>
            <div className='container'>
                <div className='div11 custom_css'>
                    <div style={{marginLeft:"25px"}}>
                        <span><h5><b>Setting</b></h5></span>
                        <p>We are Launching an app focused on Product Review and Site Review, all the features are available in our basic, free, and plans. We also provide 7days free trials.</p>
                        <h6 className='mt-2'><b>Product Review</b></h6>

                        <p>1. We can manage it by setting it in the admin panel.</p>
                        <p>2. We provide auto-approval by which the review will be posted without any approval.</p>
                        <p>3. If you activate the Enable /Disable option, you will get the option to hide all the reviews on the Product page. </p>

                        <p>4. Date and time of review can be checked by enabling date and time.</p>
                        <p>5. We provide the Only Verified Users button by which verified only user's review will be seen.</p>
                        <p>6. We provide a filter option to check reviews as per their ratings, rating status will be provided to check review approval status, and we also can select verified and non-verified customers.
                            <p>7.  We can also customize and add self-review.</p>
                            <p>8. Appearance option will be available to customize the star and button colors and size and fonts can also be customized.</p>

                            <p>9. Admin can add dummy reviews also the review can be edited or deleted.</p>
                            <p>10. Guest and logged-in users can also add reviews</p>
                            <p>11. Chat Support option available to reach our team.</p>
                        </p>
                        <br></br>

                        <span><h6><b>Site Review</b></h6></span>
                        <p>1.  We can manage it by setting it in the admin panel.</p>
                        <p>2. We provide auto-approval by which the review will be posted without any approval.</p>
                        <p>3. If you activate the Enable /Disable option, you will get the option to hide all the reviews on the product page.</p>
                        <p>4. Date and time of review can be checked by enabling date and time</p>
                        <p>5. We provide the Only Verified Users button by which verified only user's review will be seen.</p>
                        <p>6. We provide a filter option to check reviews as per their ratings, rating status will be provided to check review approval status, and we also can select verified and non-verified customers.</p>
                        <p>7. Review positioning will be provided.</p>
                        <p>8. Admin can add dummy reviews also the review can be edited or deleted.</p>
                        <p>9. Guest and logged-in users can also add reviews.</p>

                        <br></br>

                        <span><h6><b>NPS Review</b></h6></span>
                        <p>1. We can manage it by setting it in the admin panel.</p>
                        <p>2. We provide auto approve by which the review will be posted without any approval.</p>
                        <p>3. If you activate the Enable /Disable option, you will get the option to hide all the reviews on the product page.</p>
                        <p>4. Date and time of review can be checked by enabling date and time.</p>
                        <p>5. The form will be visible after payment and a form will be shown to get the website review</p>
                        <p>6. Once the user submits the form, a review will be visible on the admin panel, after admin approval. </p>
                        <br></br>
                        <p style={{ color: "red" }}>Note: Image upload and Review title will not be provided in free plans and site review is not available in free plan, enable /disable option, which will help in auto approving and will delate all the reviews</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index