import config from '../../utils/config'
import { useSearchParams } from "react-router-dom";
// const { apikey, scopes, redirecturl } = config;


const apikey="abbb5b3546b93758153439b167ab7248"
const scopes="write_script_tags,write_customers,read_customers,read_script_tags,read_products,write_products"
const redirecturl="https://productreview.ens.enterprises/auth/callback"


const UrlPage = ({input}) => {
    const [searchParams] = useSearchParams();
    const shop = searchParams.get("shop");
    const hmac = searchParams.get("hmac") || "984294fhsjkdfkjdsfk";
    const oAuthUrl = `https://${shop ? shop : input}/admin/oauth/authorize?client_id=${apikey}&scope=${scopes}&redirect_uri=${redirecturl}&state=${hmac}`;
    window.location.href = oAuthUrl;
    return (
        <img src="asset/giphy1.gif" alt="..." style={{position:'absolute', top:'15vh', left:'65vh'}}/>
    );
}

export default UrlPage;