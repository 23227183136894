import React, { useEffect } from "react";
import Layout from "../../components/layout";
import '../Nps review/npsReview.css';
import { HiOutlineStar } from "react-icons/hi2";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { useState } from "react";
import image from '../../icons/facewash.webp';
import { MdPersonOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { ShopName } from "../token/ShopName";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
};

const reviewsData = [];
const text =
    "I liked the product, lipstick would have been better.I liked the product, lipstick would have been better.";
const maxLength = 30;
const truncatedText =
    text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

function NPSReview() {
    const shopname = ShopName()
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = React.useState(false);
    const [plan, setPlan] = useState([])
    const [NpsReview, setNpsReview] = useState([])
    const [npsmodal, setNpsmodal] = useState()

    const handleOpen = (e) => {
        setNpsmodal(e)
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const editReview = () => { };

    const deleteReview = () => {
        console.log("delete review");
    };

    const showtext = () => {
        alert("qwertyu");
    };
    const pageSize = 5; // Number of items per page

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatednpsData = NpsReview?.slice(startIndex, endIndex);

    const totalPages = Math.ceil(NpsReview?.length / pageSize);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleNpsReviewData = async () => {
        try {
            const config = await axios.get(`https://productreview.ens.enterprises/getSiteReviews?shopName=${shopname}`, {
            })
            // console.log("==============", config.data.data)
            setNpsReview(config.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    console.log("==========nps data", NpsReview)

    const handleDPlanDetail = async () => {
        try {
            const config = await axios.post("https://productreview.ens.enterprises/planContoller/getPlanDetail", {
                shop: shopname,
            })
            // console.log("==============", config.data.planName)
            setPlan(config.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        handleDPlanDetail()
        handleNpsReviewData()
    }, [])
     // -------------------------twak-----support--
     var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
     (function () {
         var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
         s1.async = true;
         s1.src = 'https://embed.tawk.to/60b07e5a6699c7280da95c20/1f6omr56c';
         s1.charset = 'UTF-8';
         s1.setAttribute('crossorigin', '*');
         s0.parentNode.insertBefore(s1, s0);
     })();
    return (
        <div style={{ height: "600px" }}>
            {
                plan.status === 200 ?
                    <>

                        {
                            plan?.planName === "free" ?
                                <p className="fs-3 text-center text-primary">

                                    To unlock this feature, please upgrade your plan. </p>
                                :
                                <>

                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                            >
                                                Description
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                {npsmodal}
                                            </Typography>
                                        </Box>
                                    </Modal>
                                    <div className="container mt-2">
                                        <div className="row mb-2 review_nav">
                                            <div className="col-lg-4 d-flex gap-4">
                                                <div>
                                                    <select name="Rate" className="form-select drop_select">
                                                        <option value="" selected>
                                                            Rate
                                                        </option>
                                                        <option value="">1 star</option>
                                                        <option value="">2 star</option>
                                                        <option value="">3 star</option>
                                                        <option value="">4 star</option>
                                                        <option value="">5 star</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <select name="Status" className="form-select drop_select">
                                                        <option value="" selected>
                                                            Status
                                                        </option>
                                                        <option value="">All</option>
                                                        <option value="">Approved</option>
                                                        <option value="">Disapproved</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 d-flex justify-content-between gap-4">
                                                <div className="d-flex w-75">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search"
                                                    />
                                                    <button className="btn btn-light border drop_select ms-1">
                                                        Search
                                                    </button>
                                                </div>
                                                <div className="d-flex">
                                                    {/* <button className="form-control drop_select btn btn-primary">
                                                        Import
                                                    </button>
                                                    <button className="form-control drop_select btn-primary btn ms-2">
                                                        Export
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>

                                        <table className="border review-list">
                                            <tr>
                                                <th className="review-id">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" />
                                                    </div>
                                                </th>

                                                <th className="nps_name">
                                                    <div>Rating</div>
                                                </th>
                                                <th className="nps_name">
                                                    <div>Customer</div>
                                                </th>
                                                <th className="product_Availability">
                                                    <div className="">Description</div>
                                                </th>
                                                <th className="heading">
                                                    <div className="">website Experience</div>
                                                </th>
                                                <th className="product_Availability">
                                                    <div className="">product Availability</div>
                                                </th>
                                                <th className="discountandoffer">
                                                    <div className="">discounts and Offers</div>
                                                </th>

                                            </tr>
                                            {
                                                paginatednpsData?.map((value, index) => {
                                                    return (
                                                        <tr>
                                                            <td className="review-id">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox" />
                                                                </div>
                                                            </td>

                                                            <td className="star_rate">

                                                                {value.recommend == 5 ? (
                                                                    <>
                                                                        <span >
                                                                            <i class="fa fa-star selected"></i>
                                                                            <i class="fa fa-star selected"></i>
                                                                            <i class="fa fa-star selected"></i>
                                                                            <i class="fa fa-star selected"></i>
                                                                            <i class="fa fa-star selected"></i>

                                                                        </span>

                                                                    </>

                                                                ) : (
                                                                    <>
                                                                        {value.recommend > 0 && (
                                                                            <>
                                                                                {[...Array(value.recommend)].map((data, index) => (
                                                                                    <span key={index}>
                                                                                        {data == 4 ? (
                                                                                            <>
                                                                                                <i class="fa fa-star selected"></i>
                                                                                                <i class="fa fa-star selected"></i>
                                                                                                <i class="fa fa-star selected"></i>
                                                                                                <i class="fa fa-star selected"></i>
                                                                                                <i class="fa fa-star emptystar"></i>
                                                                                            </>
                                                                                        ) : (data == 3 ? (
                                                                                            <>
                                                                                                <i class="fa fa-star selected"></i>
                                                                                                <i class="fa fa-star selected"></i>
                                                                                                <i class="fa fa-star selected"></i>
                                                                                                <i class="fa fa-star emptystar"></i>
                                                                                                <i class="fa fa-star emptystar"></i>
                                                                                            </>
                                                                                        ) : (data == 2 ? (
                                                                                            <>
                                                                                                <i class="fa fa-star selected"></i>
                                                                                                <i class="fa fa-star selected"></i>
                                                                                                <i class="fa fa-star emptystar"></i>
                                                                                                <i class="fa fa-star emptystar"></i>
                                                                                                <i class="fa fa-star emptystar"></i>
                                                                                            </>
                                                                                        ) : (data == 1 ? (
                                                                                            <>
                                                                                                <i class="fa fa-star selected"></i>
                                                                                                <i class="fa fa-star emptystar"></i>
                                                                                                <i class="fa fa-star emptystar "></i>
                                                                                                <i class="fa fa-star emptystar"></i>
                                                                                                <i class="fa fa-star emptystar"></i>
                                                                                            </>
                                                                                        ) : (null))))}


                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}

                                                            </td>
                                                            <td className="nps_name">
                                                                <div>{value.name}</div>
                                                            </td>
                                                            <td className="product_Availability">
                                                                <div>
                                                                    <p onClick={() => { handleOpen(value.description) }}>
                                                                        {value.description.length > 20 ? value.description.substring(0, 20) + "..." : value.description}
                                                                    </p>
                                                                </div>
                                                            </td>
                                                            <td className="heading">
                                                                <div className={`${value.websiteExperience == true ? "text-success text_align" : "text-danger text_align"}`}>
                                                                    {(value.websiteExperience == true ? "true" : "false")}
                                                                </div>
                                                            </td>
                                                            <td className="product_Availability">
                                                                <div className={`${value.productAvailability == true ? "text-success text_align" : "text-danger text_align"}`}>
                                                                    {(value.productAvailability == true ? "true" : "false")}
                                                                </div>
                                                            </td>
                                                            <td className="status">
                                                                <div className={`${value.discountsandOffers == true ? "text-success text_align" : "text-danger text_align"}`}>
                                                                    {(value.discountsandOffers == true ? "true" : "false")}
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )

                                                })
                                            }


                                        </table>

                                        <div className="pagination justify-content-center">
                                            <button
                                                className="btn btn-primary me-3"
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                disabled={currentPage === 1}
                                            >
                                                {/* Previous */}◀️
                                            </button>
                                            <span>
                                                {" "}
                                                Page {currentPage} of {totalPages}{" "}
                                            </span>
                                            <button
                                                className="btn btn-primary ms-3"
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                disabled={currentPage === totalPages}
                                            >
                                                {/* Next */} ▶️
                                            </button>
                                        </div>
                                    </div>
                                </>
                        }
                    </>
                    :
                    <>
                        <div className='d-flex justify-content-center'>
                            <div className="spinner-border text-primary" role="status" style={{marginTop:"200px"}} >
                                <span className="visually-hidden ">Loading...</span>
                            </div>
                        </div>
                    </>
            }


        </div>
    )
}

export default NPSReview;