// Outlet.js
import { Link, useLocation } from 'react-router-dom';
import './component.css';
import { useState } from 'react';
import "../pages/home.css";
import { useEffect } from 'react';
import { IoMdHome } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { VscPreview } from "react-icons/vsc";
import { TbDeviceIpadStar } from "react-icons/tb";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { IoIosStats } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { BiSolidFileCss } from "react-icons/bi";
import { BiSupport } from "react-icons/bi";

const routes = [
    { path: "/Dashboard", label: "Dashboard", icon: IoMdHome },
    { path: "/Document", label: "Document", icon: IoIosDocument },
    { path: "/settings", label: "Settings", icon: IoSettingsOutline },
    { path: "/Appereance", label: "Appereance", icon: VscPreview },
    { path: "/ProductReviews", label: "Product Reviews", icon: TbDeviceIpadStar },
    { path: "/SiteReview", label: "Site Reviews", icon: TbDeviceDesktopAnalytics },
    { path: "/NPSReview", label: "NPS Reviews", icon: IoIosStats },
    { path: "/AddQuestion", label: "Add Question", icon: MdEmail },
    // { path: "/Email", label: "Mail template", icon: MdEmail },
    { path: "/Plan", label: "Plan", icon: FaRegCalendarCheck },
    { path: "/CustomCss", label: "Custom Css", icon: BiSolidFileCss },
    { path: "/Support", label: "Support", icon: BiSupport }
];

function Sidebar() {
    const [color, setColor] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const data = sessionStorage.getItem("color");
        setColor(data);
    }, []);

    const abc = (colorvalue) => {
        setColor(colorvalue);
        sessionStorage.setItem("color", colorvalue);
    };
    return (
        <ul className={`${color === 1 ? "border border-2 ms-0 border-top-0 p-1 sidebar_toggle" : "border border-2 ms-0 border-top-0 p-1 sidebar_toggle collpase manage_sidebar"}`}
            style={{ listStyle: "none", width: "22%", fontSize: "14px",minHeight:'90vh'}}
        >
            {routes.map((route, index) => (
                <Link to={route.path} style={{ textDecoration: "none", color: "#000000" }} key={index}>
                    <li className={`${location.pathname === route.path ? "fw-semibold list_active" : "fw-semibold"}`} onClick={() => abc(index + 1)}>
                    <route.icon size={20} />
                        <span className='ms-1'>{route.label}</span>
                    </li>
                </Link>
            ))}
        </ul>
    );
}

export default Sidebar;
