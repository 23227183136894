import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import logo from '../icons/logo2 1.png';
import '../pages/home.css';
import { ShopName } from '../pages/token/ShopName';
import axios from 'axios';


function Header() {

    const shopname = ShopName();
    const [sidetoggle, setSidetoggle] = useState(false)
    const [plan, setPlan] = useState([])

    const togglesidebar = () => {
        alert("togle is working")
        setSidetoggle(!sidetoggle)
    }

    // function for  plan detail
    const handleDPlanDetail = async () => {
        try {
            const config = await axios.post("https://productreview.ens.enterprises/planContoller/getPlanDetail", {
                shop: shopname,
            })
            console.log("==============", config.data)
            setPlan(config.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        handleDPlanDetail()
    }, [])

    // console.log(plan.freeTrailStatus.timeUsed)
    // console.log(plan.freeTrailStatus)
    return (
        <div>

            <div className='d-flex justify-content-between border-bottom border-2 Header'>

                <div className='d-flex'>
                    <Link to={"/"} className='logo_design'>
                        <img src="asset/review-logo.png" className='image mt-1 ms-5' />
                    </Link>
                    <p className='fw-semibold fs-5 p-3'>Your Current Plan <Link to={'/Plan'} className='text-light text-decoration-none'><span className='plan_name_css'>{plan?.planName}</span></Link> </p>

                 </div>


                <div className='d-flex justify-content-around align-items-center me-3' >

                    {
                        plan.planName == 'freeTrial'?<>< span className="fw-semibold fs-6">Days Remaining :<span className="text-danger"> {7 - plan.freeTrailStatus.timeUsed}</span></span></>:<></>
                        
                    }
                <button className='btn btn button_bg_css ms-2' id='plan-btn'>
                    <Link to={'/Plan'} className='text-light text-decoration-none'>Upgrade Plan</Link>
                </button>

            </div>

        </div>
        </div >
    )
}

export default Header