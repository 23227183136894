import React from 'react';
import { jwtDecode } from 'jwt-decode';


function ShopName() {

    const code = sessionStorage.getItem("code")
    const decoded = jwtDecode(code)
    const shopname = decoded.jwttoken
    return shopname
  
}

export  {ShopName}