import React from 'react';
import { useState } from "react";
import Layout from '../../components/layout';
import { HiOutlineStar } from "react-icons/hi2";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Rating } from 'react-simple-star-rating';
import { useNavigate } from 'react-router-dom';


function Edit({ updateData, setUpdateData }) {

   const nevigate = useNavigate()

    const handleEditProductReview = (e) => {
        setUpdateData({
            ...updateData,
            [e.target.name]: e.target.value
        })
    }

    const starRating = (star) => {
        setUpdateData({
            ...updateData,
            rating: star
        })
    }
    var storedValue = sessionStorage.getItem('code');
    // approve or dissaprove comment
    const handleAapproveDissaprove = async (e, p_id) => {
        // console.log(e.target.value,p_id)
        try {
            const config = await axios({
                url: "https://productreview.ens.enterprises/ratingContoller/approvedComment",
                headers: {
                    'Authorization': storedValue,
                    'Content-Type': 'application/json'
                },
                method: "POST",
                data: {
                    id: p_id,
                    published: e.target.value
                }
            })
            // console.log("review filter data", config.data)

        } catch (error) {
            console.log(error)
        }
    }

    // update product review 
    const handleUpdatedata = async () => {
        // console.log(e.target.value,p_id)
        try {
            const config = await axios({
                url: "https://productreview.ens.enterprises/ratingContoller/approvedComment",
                headers: {
                    'Authorization': storedValue,
                    'Content-Type': 'application/json'
                },
                method: "POST",
                data: {
                    id: updateData._id,
                    title: updateData.title,
                    body: updateData.body,
                    rating: updateData.rating,
                    // published: "",
                    // reply: ""
                }
            })
            if (config.status === 200) {
                toast("Update succesfully..!")
            }
        nevigate("/ProductReviews")


        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className='d-flex flex-column align-items-center  mt-3' style={{ height: "600px" }}>
                <div className=' p-3 m-2' style={{ background: "#FFFFFF", boxShadow: "0px 0px 5px gray", width: "45%" }}>
                    <div className='d-flex'>
                        <p>Rating </p>
                        <div >

                            <Rating onClick={(e) => { starRating(e) }}
                                initialValue={updateData?.rating}
                                name="rating"
                            />

                        </div>
                    </div>

                    <div className='d-flex'>
                        <p className='mt-2'>Title </p>
                        <input type='text' className='form-control m-3'
                            value={updateData?.title}
                            onChange={(e) => { handleEditProductReview(e) }}
                            name='title'
                        />
                    </div>
                    <div className='d-flex'>
                        <p className='mt-2'>Body </p>
                        <textarea className='form-control m-3' style={{ height: "150px" }}
                            value={updateData?.body}
                            name='body'
                            onChange={(e) => { handleEditProductReview(e) }}
                        ></textarea>
                    </div>

                    <div className='d-flex'>
                        <label>status </label>
                        <select name="published" style={{ marginLeft: "31px", width: "30%" }}
                            className={`${updateData?.published === "true" ? "text-success drop_select form-select" : "text-danger drop_select form-select"}`}
                            onChange={(e) => { handleAapproveDissaprove(e, updateData._id) }}
                        >
                            <option value="true" selected={updateData?.published === "true" ? true : false}>Approved</option>
                            <option value="false" selected={updateData?.published === "false" ? true : false}>Disapproved</option>
                        </select>
                    </div>
                </div>
                <button className='btn btn-primary mt-4' onClick={handleUpdatedata}>Update</button>
                <ToastContainer />
            </div>
        </>
    )
}

export default Edit;