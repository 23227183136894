import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Rating } from 'react-simple-star-rating'
import { ToastContainer, toast } from 'react-toastify';
import { ShopName } from '../../token/ShopName';
import { useNavigate } from 'react-router-dom';


function AddSiteReview() {

    const nevigate = useNavigate()
    const shopname = ShopName()

    const [addreview, setAddReview] = useState({
        published: "",
        productreviewtittle: "",
        productreviewtext: "",
        reviewDate: "",
        autherName: "",
        email: "",
        rating: "",
        custmoerId: ""

    })

    const payload = addreview.shopName = shopname
    const handleAddReview = (e) => {
        setAddReview({
            ...addreview,
            [e.target.name]: e.target.value
        })
    }

    const handleRating = (rate) => {
        setAddReview({
            ...addreview,
            rating: rate
        })
    }

    const handlePostReview = async () => {
        try {
            const config = await axios.post("https://productreview.ens.enterprises/ratingSiteContoller/addDummyDataSiteRewiew", {

                shopName: shopname,
                rating: addreview.rating,
                customer_id: addreview.custmoerId,
                title: addreview.productreviewtittle,
                body: addreview.productreviewtext,
                published: addreview.published,
                reviewDate: addreview.reviewDate,
                customer_email: addreview.email,
                customer_name: addreview.autherName,
                //   reply:""

            })
            // console.log(config.status)
            if (config.status === 201) {
                toast("Site Review Updated Successfully..!")
            }
            nevigate("/SiteReview")

        } catch (error) {
            console.log(error)
        }
    }

    const cancelForm = () => {
        nevigate("/SiteReview")
    }

    return (
        <>
            <div style={{ height: "600px" }}>



                <div className='w-75 p-4 container shadow mt-1' style={{ background: "white" }}>
                    <div className='container'>
                        <p>Add Site Review</p>

                        <hr />

                        <div className='d-flex justify-content-between'>
                            <div className="mb-3 col-5">
                                <label className="form-label">custmoer Id</label>
                                <input type='text' name='custmoerId' className='form-control' onChange={handleAddReview} />

                            </div>
                            <div className="mb-3 col-5">
                                <label className="form-label">Review date</label>
                                <input type="date" name='reviewDate' className="form-control" onChange={handleAddReview} />
                            </div>
                        </div>

                        <div className='d-flex justify-content-between mb-2'>
                            <div className="mb-3 col-5">
                                <label className="form-label">Auther Name</label>
                                <input type="text" name='autherName' value={addreview.autherName} className="form-control" onChange={handleAddReview} />

                            </div>
                            <div className="mb-3 col-5">
                                <label className="form-label">Email</label>
                                <input type="email" name='email' value={addreview.email} className="form-control" onChange={handleAddReview} />
                            </div>
                        </div>


                        <label className='form-label'>Rating </label>
                        <div className='mb-2 d-flex justify-content-between'>
                            <Rating onClick={handleRating} value={addreview.rating} name="rating" />

                            <div className="mb-3 col-5">
                                <label className="form-label">Published</label>
                                <select name='published' value={addreview.published}
                                    className="form-select drop_select clear"
                                    onChange={handleAddReview}
                                >
                                    <option selected >Published</option>
                                    <option value="1">Approved</option>
                                    <option value="0">DisApproved</option>

                                </select>

                            </div>
                        </div>



                        <div class="mb-3">
                            <label class="form-label">Review Tttle</label>
                            <input type="text" class="form-control" name='productreviewtittle' onChange={handleAddReview} />

                        </div>
                        <div class="mb-3">
                            <label class="form-label">Review Text</label>
                            <input type="text" class="form-control" name='productreviewtext' onChange={handleAddReview} />
                        </div>

                        <div className='d-flex justify-content-end'>

                            <button type="submit" className="btn btn-secondary"
                                onClick={cancelForm}
                            >Cancel
                            </button>

                            <button type="submit" className="btn btn-primary ms-3"
                                onClick={handlePostReview}
                            >Add review
                                <ToastContainer />
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSiteReview;