import React from 'react';
import Layout from '../../components/layout';
import JoditEditor from 'jodit-react';
import { useState } from "react";

function Email() {

    const [update, setUpdate] = useState()
 const abc = (e) =>{
    setUpdate(e)
 }

    return (
        <>
            <div className='d-flex'>
                <div className='col-6 m-2'>
                    <JoditEditor 
                     onChange={abc}
                    />
                </div>
                <div className='col-5'
                dangerouslySetInnerHTML={ { __html : update}}
                >
                  
                  
                </div>
            </div>
        </>
    )
}

export default Email;



