
import './App.css';
import Home from './pages/home';
import { Route, Routes } from 'react-router-dom';
import Appereance from './pages/Appereance';
import CustomCss from './pages/CustomCss';
import Plan from './pages/Plan';
import Reviews from './pages/Reviews';
import Settings from './pages/settings';
import Dashboard from './pages/Dashboard';
import Support from './pages/support/support';
import { useState } from 'react';
import Installer from '../src/pages/Installer';
import Edit from './pages/Editreview/Edit';
import NPSReview from './pages/Nps review/NPSReview';
import SiteReview from './pages/site review/SiteReview';
import AddReview from './pages/addReview/AddReview';
import Email from './pages/email/Email';
import EditSiteReview from './pages/site review/editSiteReview/EditSiteReview';
import AddSiteReview from './pages/site review/addsitereview/AddSiteReview';
import Layout from './components/layout';
import Document from "./pages/Document/index"
// import Protected from "./protected/index"
import { useSearchParams } from "react-router-dom";
import {decodeChecker}  from "./utils/helper/index";
import NotFound from "./pages/401";
import AddQuestion from './pages/AddQuestion/AddQuestion';
function App() {
  const [input, setInput] = useState()
  const [updateData, setUpdateData] = useState({
    id: "",
    published: "",
    title: "",
    body: "",
    rating: "",
    reply: ""
  })
  const [siteReviewUpdate, setSiteReviewUpdate] = useState({
    rating: "",
    customer_name: "",
    title: "",
    body: "",
    published: ""
  })

  let [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const localcodeGet = sessionStorage.getItem("code");
  const sessionChecker = decodeChecker(code) || decodeChecker(localcodeGet);

  if (decodeChecker(code)){
    sessionStorage.setItem("code", code);
    
}


  const getSiteReviewUpdateData = (e) => {
    setSiteReviewUpdate(e)
  }
  // console.log(siteReviewUpdate)

  const getupdtedata = (e) => {
    setUpdateData(e)
  }

  return (
    <>
      <Routes>
        
        <Route path='/' element={<Home input={input} setInput={setInput} />}></Route>
        <Route path='/' element={<Layout />}>

        <Route exact path="*" element={<p>404 Page not found.........!</p>} />
          <Route path='/shopify' element={<Installer input={input} />}></Route>
          <Route path='/Appereance' element={<Appereance />}></Route>
          <Route path='/CustomCss' element={<CustomCss />}></Route>
          <Route path='/Plan' element={<Plan />}></Route>
          <Route path='/AddQuestion' element={<AddQuestion />}></Route>
          <Route path='/Document' element={<Document />}></Route>
          <Route path='/ProductReviews' element={<Reviews getupdtedata={getupdtedata} />}></Route>
          <Route path='/SiteReview' element={<SiteReview getSiteReviewUpdateData={getSiteReviewUpdateData} />}></Route>
          <Route path='/SiteReview/EditSiteReview' element={<EditSiteReview siteReviewUpdate={siteReviewUpdate} setSiteReviewUpdate={setSiteReviewUpdate} />}></Route>
          <Route path='/SiteReview/AddSiteReview' element={<AddSiteReview />}></Route>
          <Route path='/NPSReview' element={<NPSReview />}></Route>
          <Route path='/Settings' element={<Settings />}></Route>
          <Route path='/Dashboard' element={<Dashboard />}></Route>
          <Route path='/Support' element={<Support />}></Route>
          <Route path='/ProductReviews/Edit' element={<Edit updateData={updateData} setUpdateData={setUpdateData} />}></Route>
          <Route path='/ProductReviews/AddReview' element={<AddReview />}></Route>
          <Route path='/Email' element={<Email />}></Route>
        </Route>
      </Routes>

    </>
  );
}

export default App;


