import React, { useEffect } from "react";
import Layout from "../components/layout";
import "./reviews.css";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { useState } from "react";
import { MdPersonOutline } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import papa from 'papaparse';
import { ShopName } from "./token/ShopName";
import { ToastContainer, toast } from 'react-toastify';
import { saveAs } from 'file-saver';

// comment modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};


function Reviews({ getupdtedata }) {

  const shopname = ShopName()

  const [allReview, setAllReview] = useState({
    Rating: "",
    Status: "",
    Customer: "",
    approvecomment: ""
  })

  const [currentPage, setCurrentPage] = useState(1);
  const [productreview, setProductReview] = useState([]);
  const [open, setOpen] = useState(false);
  const [modaldata, setModaldata] = useState()

  const [openproduct, setOpenProduct] = useState(false)
  const [productmodaldata, setaparoductmodaldata] = useState()

  const [openheading, setopenHeading] = useState(false)
  const [headingmodal, setHeadingmodal] = useState()

  const [selectedFile, setSelectedFile] = useState(null);
  const [update_data, setUpdate_data] = useState();


  const [ratings, setRatings] = useState()
  const [status, setStatus] = useState()
  const [customar, setCustomar] = useState()
  const [searching, setsearching] = useState()
  const [apistatus, setApistatus] = useState()

  const [importopen, setImportOpen] = useState(false)
  const [importLoader, setImportLoader] = useState(false)

  const [dataInCSV, setDataInCSV] = useState("");

  const handleOpenImportFileModal = () => {
    setImportOpen(true)
  };

  const handleCloseImportFileModal = () => setImportOpen(false)

  const handleOpenheading = (e) => {
    setHeadingmodal(e)
    setopenHeading(true)
  };

  const handleCloseHeading = () => setopenHeading(false)

  const handleOpen = (e) => {
    setModaldata(e)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const handleproductmodalopen = (e) => {
    setaparoductmodaldata(e)
    setOpenProduct(true)
  };
  const handleproductmodalclose = () => setOpenProduct(false);

  const pageSize = 6; // Number of items per page

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = productreview.slice(startIndex, endIndex);

  const totalPages = Math.ceil(productreview.length / pageSize);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  var storedValue = sessionStorage.getItem('code');

  const handlechange = (e) => {
    setAllReview({
      ...allReview,
      [e.target.name]: e.target.value
    })
  }


  // confirmation for delete the review

  function confirmDeleteproduct(id) {
    var confirmation = window.confirm("Are you sure you want to delete this Review?");
    if (confirmation) {
      deleteReview(id)
    } else {
      toast.error("Review Delete process failed", {
        autoClose: 1500
      });
    }
  }
  // api for delete the review

  const deleteReview = async (prod_id) => {
    // console.log(prod_id)
    try {
      const config = await axios.post("https://productreview.ens.enterprises/ratingContoller/deleteReview", {
        id: prod_id
      })
      // console.log(config.status)
      if (config.status === 200) {
        toast.success("Review Delete successfully", {
          autoClose: 1500
        })
      }
      handleproductByStatus()
    } catch (error) {
      console.log(error)
    }
  }



  // approve or dissaprove comment

  const handleAapproveDissaprove = async (e, p_id) => {
    console.log(e.target.value)
    try {
      const config = await axios({
        url: "https://productreview.ens.enterprises/ratingContoller/approvedComment",
        // headers: {
        //   'Authorization': storedValue,
        //   'Content-Type': 'application/json'
        // },
        method: "POST",

        data: {
          id: p_id,
          published: e.target.value,
          // shopName:shopname
        }
      })
      console.log("review filter data", config.data)
      setUpdate_data(config.data)

    } catch (error) {
      console.log(error)
    }
  }

  // console.log("storedValue",storedValue)
  // filter product by there status
  const handleproductByStatus = async (e) => {

    try {
      const config = await axios({
        url: "https://productreview.ens.enterprises/ratingContoller/findDataRatingStatus",
        headers: {
          'Authorization': storedValue,  
          'Content-Type': 'application/json' 
        },
        method: "POST",
        data: {
          shopName: shopname,
          rating: ratings,
          published: status,
          customerId: customar,
          searchTerm: searching,
          regexFlags: ""

        }
      })
      console.log("review filter data", config.status)
      setApistatus(config.status)
      setProductReview(config.data.data)

    } catch (error) {
      console.log(error)
    }
  }
  // handleAapproveDissaprove()
  useEffect(() => {
    handleproductByStatus()
    // handleproductByStatus()
  }, [ratings, update_data, searching, status, customar])


  const importFile = () => {
    document.getElementById('fileInput').click();
  }
  // -------------------------------------------csv upload ------------
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    setImportLoader(true)
    const formData = new FormData();
    formData.append('csv', selectedFile);

    try {
      const response = await axios.post(`https://productreview.ens.enterprises/importcsv?shopName=${shopname}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      // setImportLoader(true)
      setImportOpen(false)
      console.log('File uploaded successfully:', response);
      handleproductByStatus()
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  // const fileselect = (e) => {
  //   const getfile = e.target.files[0]
  //   papa.parse(getfile, {
  //     header: true,
  //     complete: (result) => {
  //       setFile(result.data)
  //     }
  //   })
  // }

  // const handleImportCsv = async () => {
  //   // console.log(shopname)
  //   try {
  //     const config = await axios.post(`https://productreview.ens.enterprises/importcsv?shopName=${shopname}`, {
  //       csvFile: file,
  //     })
  //     console.log(config)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // console.log(productreview)


  // -------------------------twak-----support--
  var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/60b07e5a6699c7280da95c20/1f6omr56c';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();

  // const exportCSV = async () => {
  // try {
  //   const config = await axios.get(`https://productreview.ens.enterprises/exportcsv?shopName=${shopname}`)
  //   console.log("data", config.data)
  //   setDataInCSV(config.data)
  // } catch (error) {
  //   console.log(error)
  // }
  // }
  const handleDownload = async () => {
    try {
      // Fetch CSV data from the API
      const response = await fetch(`https://productreview.ens.enterprises/exportcsv?shopName=${shopname}`);
      const csvData = await response.text();

      const blob = new Blob([csvData], { type: 'text/csv' });

      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      a.style.display = 'none';
      document.body.appendChild(a);

      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };
 
  const DownloadCSV = () => {
    const headers = [
      "shopName",
      "rating",
      "customer_id",
      "product_id",
      "product_handle",
      "sku",
      "title",
      "body",
      "published",
      "reviewDate",
      "customer_email",
      "customer_name",
      "reply",
      "images",
    ];

    // Dummy data for each column
    const dummyData = [
      "DummyShop",
      "5",
      "123",
      "456",
      "dummy_handle",
      "dummy_sku",
      "Dummy Product Title",
      "Dummy Review Body",
      "true",
      "2024-04-04",
      "dummy@example.com",
      "Dummy Customer",
      "Dummy Reply",
      "dummy_image.jpg"
    ];
 
    const orderList = Array.from({ length: 2 }, () => dummyData);

    const data = orderList.map((item) => {
      return item.join(',');
    });

    const csvContent = [headers.join(','), ...data].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `orders_${new Date().getTime()}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  }


  return (

    <div className="container mt-2" style={{ height: "600px" }}>
    {
      apistatus == 200 ?
        <>
          <div className="row mb-2 review_nav">
            <div className="col-lg-5 d-flex gap-4">
              <div>
                <select name="Rating" className="form-select drop_select text-muted" onChange={(e) => { setRatings(e.target.value) }}
                >
                  <option value="" selected>Rating</option>
                  <option value="">All</option>
                  <option value="1">1 star</option>
                  <option value="2">2 star</option>
                  <option value="3">3 star</option>
                  <option value="4">4 star</option>
                  <option value="5">5 star</option>
                </select>
              </div>
              <div>
                <select name="Status" className="form-select drop_select text-muted" onChange={(e) => { setStatus(e.target.value) }}>
                  <option value="" selected>
                    Status
                  </option>
                  <option value="">All</option>
                  <option value="1">Approved</option>
                  <option value="0">Disapproved</option>
                </select>
              </div>

              <div>
                <select name="Customer" className="form-select drop_select text-muted" onChange={(e) => { setCustomar(e.target.value) }} >
                  <option value="" selected>
                    Customer
                  </option>
                  <option value="true">Verified</option>
                  <option value="false">Unverified</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 d-flex justify-content-between gap-4">
              <div className="d-flex w-75">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                  onChange={(e) => setsearching(e.target.value)}
                />
                {/* <button className="btn btn-light border drop_select ms-1">
              Search
            </button> */}
              </div>

              <div className="d-flex">

                <button className="btn btn-primary me-2">
                  <Link to={'./AddReview'} className=" text-light text-decoration-none">
                    Add
                  </Link>
                </button>

                <button className="btn btn-primary form-control drop_select "
                  onClick={handleOpenImportFileModal}
                >
                  Import
                </button>
                <button className="btn btn-primary form-control drop_select  ms-2" onClick={handleDownload}>
                  Export
                </button>
                <button className="btn btn-primary form-control drop_select  ms-2" onClick={DownloadCSV} >
                  Sample
                </button>
                <div class="vertical-buttons">


                </div>

              </div>
            </div>
          </div>

          <table className="border review-list rounded">


            <tr>
              <th className="review-id">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" />
                </div>
              </th>
              <th className="product">
                <div>Product</div>
              </th>

              <th className="sku">
                <div>Sku</div>
              </th>
              <th className="rating">
                <div className="">Rating</div>
              </th>
              <th className="heading">
                <div className="">Heading</div>
              </th>
              <th className="content">
                <div className="">Comment</div>
              </th>
              <th className="status">
                <div className="">Status</div>
              </th>

              <th className="actions">
                <div className="">Action</div>
              </th>
            </tr>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Comment
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {modaldata}
                </Typography>
              </Box>
            </Modal>

            <Modal
              open={openproduct}
              onClose={handleproductmodalclose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Product Name
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {productmodaldata}
                </Typography>
              </Box>
            </Modal>

            <Modal
              open={openheading}
              onClose={handleCloseHeading}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Heading
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {headingmodal}
                </Typography>
              </Box>
            </Modal>

            <Modal
              open={importopen}
              onClose={handleCloseImportFileModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Select Your File
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <input type="file" onChange={handleFileChange} />
                  <br></br>
                  <button className="btn mt-3 button_bg_css" onClick={handleUpload} disabled={!selectedFile}>
                    {importLoader == true ? <>
                      Loading..
                      {/* <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden ">Loading...</span>
            </div> */}
                    </> : <>Uploads</>}
                  </button>

                </Typography>
              </Box>
            </Modal>

            {
              paginatedData?.map((value, index) => {
                return (
                  <tr key={index}>
                    <td className="review-id">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                      </div>
                    </td>
                    <td className="product-name">
                      <p onClick={() => (handleproductmodalopen(value.product_handle))}>
                        {value?.product_handle?.length > 15 ? value?.product_handle.substring(0, 15) + "..." : value?.product_handle}
                      </p>
                      <div className="user_name">
                        <MdPersonOutline
                          style={{ width: "15px", height: "fit-content" }}
                        />
                        By coustmer :
                        {value?.customer_name}
                      </div>
                    </td>

                    <td>
                      <div className="sku">
                        <p>{value?.sku}</p>
                      </div>
                    </td>

                    <td>

                      {value.rating == 5 ? (
                        <>
                          <span >
                            <i class="fa fa-star selected"></i>
                            <i class="fa fa-star selected"></i>
                            <i class="fa fa-star selected"></i>
                            <i class="fa fa-star selected"></i>
                            <i class="fa fa-star selected"></i>

                          </span>

                        </>

                      ) : (
                        <>
                          {value.rating > 0 && (
                            <>
                              {[...Array(value.rating)].map((data, index) => (
                                <span key={index}>
                                  {data == 4 ? (
                                    <>
                                      <i class="fa fa-star selected"></i>
                                      <i class="fa fa-star selected"></i>
                                      <i class="fa fa-star selected"></i>
                                      <i class="fa fa-star selected"></i>
                                      <i class="fa fa-star emptystar"></i>
                                    </>
                                  ) : (data == 3 ? (
                                    <>
                                      <i class="fa fa-star selected"></i>
                                      <i class="fa fa-star selected"></i>
                                      <i class="fa fa-star selected"></i>
                                      <i class="fa fa-star emptystar"></i>
                                      <i class="fa fa-star emptystar"></i>
                                    </>
                                  ) : (data == 2 ? (
                                    <>
                                      <i class="fa fa-star selected"></i>
                                      <i class="fa fa-star selected"></i>
                                      <i class="fa fa-star emptystar"></i>
                                      <i class="fa fa-star emptystar"></i>
                                      <i class="fa fa-star emptystar"></i>
                                    </>
                                  ) : (data == 1 ? (
                                    <>
                                      <i class="fa fa-star selected"></i>
                                      <i class="fa fa-star emptystar"></i>
                                      <i class="fa fa-star emptystar "></i>
                                      <i class="fa fa-star emptystar"></i>
                                      <i class="fa fa-star emptystar"></i>
                                    </>
                                  ) : (null))))}


                                </span>
                              ))}
                            </>
                          )}
                        </>
                      )}

                    </td>



                    <td className="heading">
                      <p onClick={() => { handleOpenheading(value.title) }} >
                        {value?.title?.length > 8 ? value?.title.substring(0, 8) + "..." : value?.title}
                      </p>
                    </td>
                    <td className="content">
                      <p onClick={() => { handleOpen(value.body) }} className="review-full">
                        {value?.body?.length > 25 ? value?.body.substring(0, 25) + "..." : value?.body}
                      </p>


                    </td>
                    <td className="status dis">
                      <select name="approvecomment" onChange={(e) => { handleAapproveDissaprove(e, value._id) }}
                        className={`${value?.published === "1" ? "text-success drop_select form-select" : "text-danger drop_select form-select"}`}
                      >
                        <option value="1" selected={value?.published === "1" ? true : false}>Approved</option>
                        <option value="0" selected={value?.published === "0" ? true : false}>Disapproved</option>
                      </select>
                    </td>
                    <td className="actions">
                      <div className="">
                        <Link to={"./Edit"}>
                          <FaRegEdit
                            style={{ color: "#000000" }}
                            onClick={() => { getupdtedata(value) }}

                          />
                        </Link>

                        <MdDeleteOutline onClick={() => { confirmDeleteproduct(value._id) }} />
                        <ToastContainer />
                      </div>
                    </td>
                  </tr>
                )
              })}


          </table>

          <div className="pagination justify-content-center">
            <button
              className="btn btn-primary me-3"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
                ◀️
            </button>
            <span>
              {" "}
              Page {currentPage} of {totalPages}{" "}
            </span>
            <button
              className="btn btn-primary ms-3"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
            {/* <i class="fa-solid fa-circle-chevron-right"></i> */}
              {/* <i class="fa-regular fa-angle-right"></i> */}
              ▶️
            </button>
          </div>
        </>
        :
        <>
          <center>
            <div className="spinner-border text-primary " role="status" style={{ marginTop: "200px" }} >
              <span className="visually-hidden ">Loading...</span>
            </div>
          </center>
        </>
    }
  </div>

  );
}

export default Reviews;
